.main {
    display: grid;
    grid-template-rows: 80px 1fr;
    grid-template-columns: 0 450px 1fr 0;
    grid-auto-rows: 0;
    grid-auto-columns: 0;
    grid-gap: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
}

.header{
    grid-area: header;
    position: relative;
    border-bottom:1px solid #212121;
}

.storiesPreviewPanel{
    grid-area: storiesPanel;
    position: relative;
    background: #2f3138;
    text-align: center;
    height: 100%;
    width: 100%;
}


div.controlNavigation {
    border-radius: 50%;
    z-index: 1000;
    position: absolute;
    top: 30px;
    right: -40px;
    width: 80px;
    height: 80px;
    display: grid;
    grid-template-areas: 'navIcon navIcon';
    grid-auto-rows: 0;
    grid-auto-columns: 0;
    grid-gap: 0;
    margin: 0;
    -webkit-box-shadow: 3px 3px 2px -2px #212529;
    box-shadow: 3px 3px 2px -2px #212529;
    background: #343a40;
    grid-template-columns: 50% 50%;
}

div.controlNavigation:hover{
    -webkit-box-shadow: 3px 3px 2px 0 #212529;
    box-shadow: 3px 3px 2px 0 #212529;
}

div.controlNavigationIcon {
    grid-area: navIcon;
    position: relative;
    color: #D0D1D1;
    height: 100%;
    width: 100%;
    line-height: 77px;
    font-size: 32px;
    cursor: pointer;
}
div.controlNavigationIcon svg:hover {
    color: #fff;
}

.mapContainer {
    grid-area: map;
    position: relative;
    background: #ffffff;
    text-align: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.main.mainPanelOpen {
    grid-template-areas: 'header header header header' 'storiesPanel storiesPanel map map';
}


.main.mainPanelWideOpen {
    grid-template-areas: 'header header header header' 'storiesPanel storiesPanel storiesPanel map';
}

.main.mainPanelClosed {
    grid-template-areas: 'header header header header' 'storiesPanel map map map';
}