.storiesPanel{
    background: rgba(75, 75, 75, 0.58);
}
.card.article-preview{
    margin: 10px 20px;
    background-color: rgba(75, 75, 75, 0.58);
    background-clip: border-box;
    border: 0;
    border-radius: 1.2rem;
    -webkit-box-shadow: 3px 3px 2px -2px #212529;
    box-shadow: 3px 3px 2px -2px #212529;
    max-width: 370px;
    display: inline-block;
    color: #c9c9c9;
    position: relative;
}

.card.article-preview .button-block{
    display: none;
    position: absolute;
    top: 20px;
    margin: auto;
    text-align: center;
    width: 100%;
}
.card.article-preview .button-block button.transparent-white{
    background: rgba(255,255,255,.7);
    text-align: center;
    display: inline-block;
    width: 150px;
    margin: 10px;
}
.card.article-preview .button-block button.transparent-white:hover{
    color: #212529;
}

.card.article-preview:hover{
    -webkit-box-shadow: 3px 3px 2px 0 #212529;
    box-shadow: 3px 3px 2px 0 #212529;
}

.card.article-preview:hover .button-block{
    display: block;
    z-index: 100;
}
.card.article-preview img{
    border-radius: 1.2rem 1.2rem 0 0;
    border-bottom: 1px solid #212529;
}
.card.article-preview:hover img{
    opacity: 0.6;
}

.card.article-preview .card-body {
    padding: .5rem 0.75rem .25rem;
}

.card.article-preview .card-title {
    text-align: center;
    margin-bottom: 5px;
    padding-left: 0;
    font-size: 1.2rem;
}
.card.article-preview .card-subtitle {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    color: #848484;
    font-size: 0.8rem;
}

.card.article-preview .card-title a {
    color: #c9c9c9;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 29px;
    font-weight: 400;
}

.card.article-preview .card-title a:hover {
    color: #ce6365;
    text-decoration: none;
}

.card.article-preview .card-text {
    padding: 0 0 0.75rem 0;
    margin: 0;
    text-align: center;
    color: #848484;
    font-size: 0.8rem;
}
.card.article-preview .card-text a {
    color: #c9c9c9;
}

.card.article-preview .card-text a:hover {
    color: #ce6365;
    text-decoration: none;
}



.card.article-preview .card-footer{
    text-align: center;
    padding: 0 .5rem .5rem 0.5rem;
}
.card.article-preview .card-footer a{
    color: #848484;
    padding: .15rem .25rem;
    display: inline-block;
    height: 22px;
    line-height: 1.5;
    font-size: .875rem;
}

.card.article-preview .card-footer a:hover{
    color: #ce6365;
    text-decoration: none;
}

.card.article-preview .btn-group-sm.dropdown{
    display: inline-block;
}
.card.article-preview .btn-secondary {
    background-color: transparent;
    border-color: transparent;
    display: inline-block;
    outline: none !important;
    color: #848484;
    padding: .15rem .25rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}
.card.article-preview .btn-group-sm.dropdown.show .btn-secondary{
    color: #ce6365;
}
.card.article-preview .dropdown-toggle::after {
    display: none;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.card.article-preview .dropdown-menu{
    padding: .15rem 0;
    margin: 0 0 0 1.5rem;
    background: #c9c9c9;
}
.card.article-preview .dropdown-item{
    padding: .15rem .5rem;
    font-size: 13px;
}
.card.article-preview .dropdown-item:hover{
    background: #c9c9c9;
    color: #ce6365;
}

.card.article-preview .btn-secondary:hover {
    color: #ce6365;
    text-decoration: none;
}
.card.article-preview .btn-secondary:focus {
    box-shadow: none !important;
    outline: none !important;
}




/*.card.article-preview .card-text a span.place-name {*/
/*    display: inline-block;*/
/*    line-height: 20px;*/
/*    vertical-align: middle;*/
/*    padding: 0 10px 0 5px;*/
/*}*/

/*.card.article-preview .card-text span.marker {*/
/*    width: 20px;*/
/*    height: 30px;*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    background: url(./../../images/pin.png) no-repeat;*/
/*}*/

/*.card.article-preview .card-text a:hover .marker{*/
/*    background: url(./../../images/pin.png) no-repeat;*/
/*}*/
