.mapBox {
    position: relative;
    width: 100%;
    height: 100%;
}

.marker {
    border-radius: 10px 10px 10px 10px;
    width: 20px;
    height: 30px;
    background: url(./../../images/pin_grey.png) no-repeat;
    /*background-color: rgb(155, 155, 155);*/
}

.marker.hovered {
    background: url(./../../images/pin.png) no-repeat;
}
.pinMenu{
    display: block;
    position: absolute;
    z-index: 100000;
    width: 250px;
}
.pinMenuCard{
    border-radius: 1rem;
    background: #3c3d3f;
    -webkit-box-shadow: 3px 3px 2px -2px #212529;
    box-shadow: 3px 3px 2px -2px #212529;
}
.pinMenuTitle{
    margin-top: 1rem;
    margin-bottom: .25rem;
    color: #c9c9c9;
    font-style: normal;
    font-size: 1.25rem;
    text-align: center;
}
.pinMenuSubTitle{
    color: #848484;
    font-style: normal;
    font-size: .8rem;
    text-align: center;
    margin-top: 0;
    padding-bottom: .75rem;
}
.pinMenuShowAll{
    color: #848484;
    font-style: normal;
    font-size: 1rem;
    text-align: center;
    margin-top: .75rem;
    margin-bottom: .75rem;
}
.pinMenuShowAll a{
    color: #c9c9c9;
    cursor: pointer;
}
.pinMenuShowAll a:hover{
    color: #ffffff;
    text-decoration: none;
}
.scrollable{
    max-height: 350px;
    overflow-y: scroll;
    padding-bottom: 1.25rem;
    border-top: 1px solid #48494b;
}
.closePinMenu{
    position: absolute;
    right: 0;
    top: .25rem;
    border: 0;
    border-radius: 0 3px 0 0;
    padding: 10px;
    cursor: pointer;
    font-size: large;
    color: #D0D1D1;
    background-color: transparent;
    z-index: 100000;
}
.popupGrid{
    display: grid;
    grid-template-rows: 1fr ;
    grid-template-columns: 60px 1fr;
    grid-auto-rows: 0;
    grid-auto-columns: 0;
    grid-gap: 0;
    grid-template-areas: 'user read';
    padding: .25rem .5rem;
    margin: .5rem .75rem;
    border-radius: 1rem;
}

.userPreview{
    grid-area: user;
}
.storyPlacePreview{
    grid-area: read;
}
.placeStars{
    color: #FAC917;
    display: inline-block;
}
.readStory{
    margin: 0;
    padding: 0;
}
.readStory a{
    color: #848484;
    display: inline-block;
}
.readStory a:hover{
    color: #ffffff;
    text-decoration: none;
    display: inline-block;
}



:global(.mapboxgl-popup.card){
    border-radius: 1.2rem;
    max-height: 250px;
}
:global(.mapboxgl-popup-tip){
    border: 0;
}
:global(.mapboxgl-popup-content){
    padding: 0;
    -webkit-box-shadow: 0 2px 2px -1px #212529;
    box-shadow: 0 2px 2px -1px #212529;
    border-radius: 1.2rem;
}
:global(.mapboxgl-popup-content .card-title){
    margin: 0;
    width: 100%;
    background: rgba(0,0,0,0.6);
    padding: .75rem 1.5rem 0 1.5rem;
}
:global(.mapboxgl-popup-content .card-subtitle){
    width: 100%;
    margin-top: 0;
    background: rgba(0,0,0,0.6);
}
:global(.mapboxgl-popup-content .card-text){
    margin: 0;
    width: 100%;
    padding: 0 1.5rem .75rem 1.5rem;
    color: #ffffff;
    background: rgba(0,0,0,0.6);
}
:global(.mapboxgl-popup-content .card-text a){
    color: #ffffff;
    cursor: pointer;
}
:global(.mapboxgl-popup-content a){
    color: #D0D1D1;
}
:global(.mapboxgl-popup-content .card-img-overlay){
    padding: 2rem 0 0 0;
}
:global(.mapboxgl-popup-content a:hover){
    color: #ffffff;
    text-decoration: none;
}
:global(.mapboxgl-popup-content .card-img){
    padding: 0;
    border-radius: 1.2rem;
}
:global(.mapboxgl-popup.card){
    padding: 0;
}
:global(.mapboxgl-popup-close-button){
    position: absolute;
    right: 0;
    top: 2rem;
    border: 0;
    border-radius: 0 3px 0 0;
    padding: 10px;
    cursor: pointer;
    font-size: large;
    color: #D0D1D1;
    background-color: transparent;
    z-index: 100000;
}

