.navBar {
    height: 100%;
    -webkit-box-shadow: 3px 3px 2px -2px #212529;
    box-shadow: 3px 3px 2px -2px #212529;
}

.logo {
    width: 202px;
    height: 60px;
}

.iconImg {
    max-height: 50px;
    margin-right: 5px;
    border-radius: 50%;
}

.iconNav {
    font-size: 30px;
    padding-top: 0;
    line-height: 55px;
    font-weight: 100;
    color: #D0D1D1 !important;
}
.headerSearchRelative{
    position: relative;
}
.headerSearch{
    margin: 10px 20px 10px 0;
    border-radius: 1.25em;
    padding-left: 40px;
    width: 400px;
}

.headerSearchIcon{
    position: absolute;
    top: 22px;
    left: 15px
}
