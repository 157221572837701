.addsBottom {
    position: absolute;
    bottom: 10px;
    left: calc(100% / 24);
    right: calc(100% / 24);
}
.storiesPreviewArea{
    position: absolute;
    top: 0;
    bottom: 20px;
    overflow-y: scroll;
    overflow-x: visible;
    padding: 20px 20px 40px 10px;
}

.storiesList  {
    right: 5px;
}
