.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45vh;
}

.main > * {
    margin: 10px;
}
